import React from 'react';
import PropTypes from 'prop-types';

const Winners = ({ size, gStyle, viewBox }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid"
        width={size}
        height={size}
        viewBox={`0 0 ${viewBox} ${viewBox}`}
    >
        <g fill="#11589D" style={gStyle}>
            <path d="M215.65,60.631c-3.37,0.2-6.681,0.3-9.843,0.3-24.799,0-43.106-6.118-54.413-18.184l-1.343-1.436-0.06,0.055-0.051-0.054-1.343,1.436c-11.307,12.065-29.615,18.184-54.414,18.184-3.162,0-6.472-0.101-9.842-0.3l-2.078-0.119,0.292,6.612c0.187,9.756,1.117,19.632,2.763,29.342,1.846,11.581,4.437,21.869,7.904,31.411,3.436,10.418,8.153,20.418,14.851,31.474,10.45,17.03,24.233,31.989,40.968,44.46l0.951,0.711,0.951-0.711c16.734-12.471,30.517-27.43,40.967-44.46,6.698-11.056,11.415-21.056,14.852-31.474,3.466-9.542,6.057-19.83,7.903-31.411,1.646-9.71,2.576-19.586,2.763-29.342l0.292-6.612-2.08,0.116zm-10.58,34.303c-1.75,10.967-4.188,20.67-7.461,29.681l-0.076,0.218c-3.198,9.7-7.617,19.056-13.905,29.434-8.761,14.281-20.074,27.051-33.632,37.981-13.559-10.931-24.872-23.7-33.633-37.981-6.286-10.378-10.706-19.733-13.905-29.434l-0.076-0.218c-3.273-9.011-5.712-18.714-7.461-29.681-1.367-8.066-2.217-16.227-2.534-24.292,0.604,0.006,1.205,0.01,1.801,0.01,23.832,0,42.578-5.357,55.745-15.926,0.021,0.016,0.043,0.031,0.063,0.048,0.02-0.017,0.042-0.032,0.063-0.048,13.167,10.568,31.913,15.926,55.744,15.926,0.597,0,1.195-0.003,1.801-0.01-0.3,8.06-1.15,16.221-2.52,24.287" />
            <path
                fill="#FFF"
                d="M205.81,70.652c-23.831,0-42.577-5.357-55.744-15.926-0.021,0.016-0.043,0.031-0.063,0.048-0.02-0.017-0.042-0.032-0.062-0.048-13.167,10.569-31.914,15.926-55.746,15.926-0.596,0-1.196-0.003-1.801-0.009,0.316,8.064,1.168,16.225,2.534,24.291,1.75,10.967,4.188,20.67,7.461,29.681l0.076,0.218c3.199,9.7,7.618,19.056,13.905,29.434,8.761,14.281,20.074,27.051,33.632,37.981,13.558-10.931,24.872-23.7,33.632-37.981,6.288-10.378,10.707-19.733,13.905-29.434l0.076-0.218c3.273-9.011,5.711-18.714,7.461-29.681,1.367-8.066,2.218-16.227,2.533-24.291-0.61,0.001-1.21,0.004-1.8,0.004"
            />
            <path
                fill="#CF281D"
                d="M178.79,86.332l-0.021,0.137c-0.601,3.726-1.287,7.28-2.064,10.694h-0.075c-1.198,5.273-2.614,10.173-4.305,14.774l-0.34,0.971c-2.117,6.348-4.971,12.6-8.806,19.334l-13.429-34.658-13.429,34.658c-3.836-6.733-6.689-12.986-8.806-19.334l-0.34-0.972c-1.69-4.6-3.107-9.499-4.305-14.773h-0.074c-0.778-3.414-1.465-6.969-2.066-10.694l-0.021-0.137c-0.279-1.625-0.52-3.268-0.752-4.907h-12.51l0.089,0.688c0.305,2.363,0.664,4.749,1.066,7.101l0.022,0.136c1.609,9.976,3.829,18.738,6.792,26.807l0.219,0.624c2.864,8.584,6.854,16.921,12.554,26.228,0.84,1.354,1.715,2.689,2.609,4.015,2.56,3.797,5.342,7.478,8.325,11.026l10.614-27.12v0.001l10.615,27.118c2.982-3.548,5.765-7.229,8.324-11.025,0.894-1.325,1.771-2.663,2.609-4.016,5.7-9.306,9.691-17.643,12.555-26.226l0.219-0.624c2.963-8.07,5.183-16.832,6.792-26.807l0.022-0.138c0.403-2.351,0.762-4.738,1.065-7.1l0.09-0.689h-12.496c-0.22,1.647-0.46,3.29-0.74,4.916"
            />
        </g>
    </svg>
);

Winners.displayName = 'Winners';
Winners.propTypes = {
    gStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    size: PropTypes.number,
    viewBox: PropTypes.number,
};

Winners.defaultProps = {
    size: 25,
    viewBox: 244,
};

export default Winners;
